.container {
  height: 100vh;
}

.logo {
  text-align: end;
  margin-top: 10px;
}

.apply-visa {
  text-align: center;
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 100;
}

.slide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
  pointer-events: none;
}

.slide-content {
  position: relative;
  z-index: 2;
}
